import React from "react";



import {  auth, db } from "../firebase";

import {  Modal, ModalBody, Button, ModalHeader } from 'react-bootstrap';

import NotFound from "../assets/notfound.png"

import Swal from 'sweetalert2'
import toast from 'react-hot-toast';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';

import cardTherapist from "../Components/CardTherapist";
import { checkChatExists } from "../Components/Helpers";



import Chat from "./Chat";
import Settings from "./ProfileTherapist";
import LogoWhite from "../assets/logo-white.png"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MindfullnessAI from "./MindfullnessAI";



class Profile extends React.Component {



  constructor(props) {
    
    super(props) 
    this.state = {
        tab:1,
        loadingContent:true,
        loading:true,
        sesionsAmmount: 0,  
        selectedDay: null,
        selectedHour: null,
        subscribe: false,
        check:true, 
        therapistLog:false,
        sesions: [],  
        hours:[
          {hour:"9:00", reserved: false},
          {hour:"10:00", reserved: false},
          {hour:"11:00", reserved: false},
          {hour:"12:00", reserved: false},
          {hour:"13:00", reserved: false},
          {hour:"14:00", reserved: false},
          {hour:"15:00", reserved: false},
          {hour:"16:00", reserved: false},
          {hour:"17:00", reserved: false},
          {hour:"18:00", reserved: false},
          {hour:"19:00", reserved: false},
          {hour:"20:00", reserved: false},
        ],
       
    }
  }

  resetHours() {
    this.setState({
      hours: [
          {hour:"9:00", reserved: false},
          {hour:"10:00", reserved: false},
          {hour:"11:00", reserved: false},
          {hour:"12:00", reserved: false},
          {hour:"13:00", reserved: false},
          {hour:"14:00", reserved: false},
          {hour:"15:00", reserved: false},
          {hour:"16:00", reserved: false},
          {hour:"17:00", reserved: false},
          {hour:"18:00", reserved: false},
          {hour:"19:00", reserved: false},
          {hour:"20:00", reserved: false},]
    })
  }

  componentDidMount() {
    this.fetchEntries();
    const query = new URLSearchParams(this.props.location.search);
    const sp = query.get('successpay')
        if(sp == 'true') {
          this.sucessPayment()
        }
    setTimeout(() => {
        this.getUser()
    }, 2000);

  }


   async redemGift(id) {

      await  db.collection('codigos').doc(id).get().then(doc2 => {
        var d =  doc2.data()
        var userAmo = this.state.userData?.sesionsAmmount
        var sum = Number(d.amount) + Number(userAmo)
        db.collection("users").doc(auth.currentUser?.email).set({sesionsAmmount:sum},{merge:true})

    });
   
  }




  fetchEntries = async () => {
    const querySnapshot = await db.collection("users").doc(auth.currentUser?.email).collection("journalEntries").get();
    const entries = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    entries.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds); // Sort by date descending
    this.setState({ entries });
  };

  handleInputChange = (event) => {
    this.setState({ newEntry: event.target.value });
  };

  handleMoodChange = (event) => {
    this.setState({ mood: event});
  };
  addEntry = async () => {
    if (this.state.newEntry?.trim() === "") return;
    await db.collection("users").doc(auth.currentUser?.email).collection("journalEntries").add( {
      text: this.state.newEntry,
      timestamp: new Date(),
      mood:this.state.mood
    });
    this.setState({ newEntry: "" });
    this.fetchEntries();
  };

  
  sucessPayment() {
    Swal.fire({
      title: "Te has suscrito correctamente",
      text:"Has activado tu suscripción a Theraphya, ahora tendrás una sesión semanal disponible con tu terapeuta",
      icon:"success"
      })
      setTimeout(() => {
    db.collection("users").doc(auth.currentUser?.email).set({subscribe:true},{merge:true})
        
      }, 2000);
  }

  sucessReservation() {
    Swal.fire({
      title: "Sesión reservada",
      text:"Has reservado tu sesión correctamente.Tu terapeuta te contactará para confirmar la sesión",
      icon:"success"
      })
  }

  changeT() {
    this.setState({modalChange: false})
      Swal.fire({
      title: "Se ha solicitado el cambio",
      text:"Se ha solicitado el cambio de terapeuta en breve se te asignará uno nuevo.",
      icon:"success"
      })
  }

  selectDayLoad() {
    if(this.state.therapistData?.name !== undefined) {
    this.resetHours()  
    var s = new Date(this.state.selectedDay).toLocaleDateString("en-US")
    s = s.replace("/", "-")
    s = s.replace("/", "-")

    // load that day 
    db.collection("therapists").doc(this.state.therapist).collection(s).get()
      .then(res => {
          res.forEach(doc => {

              let id = doc.id;
              var myArray = this.state.hours;
              var objIndex = myArray.findIndex(obj => obj.hour == id);

              //Update object's name property.
              myArray[objIndex].reserved =  true
              this.setState({hours:myArray})
          });
      })
      this.setState({tab:2})
    } else {
      toast.error("Aun no tienes terapeuta asignado, no puedes reservar sesión");
    }
  }

  async bookSesion() {
    if(this.state.sesionsAmmount > 0) {
    let id = "id-" + Date.now();

    var s = new Date(this.state.selectedDay).toLocaleDateString("en-US")
   

    var data = {
      day: this.state.selectedDay,
      hour: this.state.selectedHour || false,
      bookDate: id,
      user: { email: auth?.currentUser?.email , name: this.state.name },
      therapistData: this.state.therapistData,
      dayDisplay: s,
    }


    s = s.replace("/", "-")
    s = s.replace("/", "-")


    this.setState({loading:true})
    await db.collection("users").doc(auth?.currentUser?.email).collection('sesions').doc(id).set(data).then(() => {
     

      db.collection("therapists").doc(this.state.therapist).collection(s).doc(this.state.selectedHour).set(data)


      db.collection("therapists").doc(this.state.therapist).collection("sesions").add(data)


      db.collection("users").doc(auth?.currentUser?.email).update({sesionsAmmount: this.state.sesionsAmmount - 1})

      this.setState({
        modalOpen: false,
        selectedDay: null,
        selectedHour: null,
        status:"reserved",
        tab:1,
      })
      this.resetHours()
      this.sucessReservation()
      this.getSesions()
      setTimeout(() =>  {
      this.getUser()
          }, 1000)
        })
      }
        else {
          toast.error("Compra sesiones de Theraphya o suscríbete");
        }
  }

  logrosfun() {
    toast.success("Haz más sesiones para desbloquear logros en tu progreso");
  }

  openSesion() {
    this.setState({modalSesion:true})

  }

renderSesion(item)  {

  return(
      
          <div className="card   shadow m-2 " onClick={() => this.openSesion(item)}>
          <div className="d-flex  align-items-center ">

       {/* "     <div className="thumbnailCard m-2">
              <img className="rounded-circle" src={item.therapistData?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
              
            </div>" */}

            <div className="avatar  mt-2 mb-3 ">
        <a>
        <img className="avatar-img rounded-circle border border-white border-3 bg-dark mx-2" src={item.therapistData?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} alt="" />
        </a>

          </div>
            <div className="mx-3">
            <h5 className=" mb-0 mt-1">Sesión <b>{this.state.therapistData?.name}</b> reservada </h5>
            <h5>{item.dayDisplay}</h5>
            </div>
            <div className="">
             <h4 className="badge bg-dark m-3">{item.hour}</h4>
              
            </div>
            <Button variant="outline-primary" text="" className="mx-4">
            Ver detalles sesión
            </Button>


            
            </div>         
        </div>
  )

}

renderSesions =  () => {

    var data = this.state.sesions;

    if(data.length > 0) {
      return(data.map(item => this.renderSesion(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center">
          <img src={NotFound} className="img-fluid" width={"200px"}></img>
          <h3 className="text-center">No tienes ninguna sesión </h3>
        </div>
        </>
      )
    }
   
  }

  getUser = async () => { 
      if(auth.currentUser) {
        await db.collection('users').doc(auth.currentUser?.email).get()
        .then(doc => {
            const data = doc.data();

            this.setState(data)
            this.setState({userData:data})
            this.setState({sesionsAmmount: data?.sesionsAmmount|| 0})
    
            if(data?.subscribe == true) {
              this.setState({ subscribe: true})
                
            }  else {
              this.setState({ subscribe: false})
            }
            
            if(data?.therapistLog !== true) {
    
              checkChatExists(auth.currentUser?.email, data?.name,data?.therapist)

              

              db.collection("therapists").doc(data?.therapist).get().then(docT => {
                var d = docT.data()
  
                const array = [auth.currentUser?.email,data?.therapist]
                array.sort();
                var id = array[0] + array[1];
                this.setState({chatId: id})
                if(d) {
                d.email = data?.therapist;
                }
                this.setState({ therapistData:d})

                db.collection("users").doc(auth.currentUser?.email).collection("chats").doc(id).get().then((doc) =>
                {
                  if(doc.exists){
                  var chatDoc = doc.data()
                  this.setState({check: chatDoc.check})
                  }
                }
                )

              })
               
            } else {
            }
        
        })
          
          this.getSesions()
          this.setState({loadingContent: false})

        } else {
            window.location.href ="/register"
        }
}

handleChangeHour = (event) => {
    this.setState({selectedHour: event.target.value});
  }

addS(newItem) {
    this.setState({ sesions: [...this.state.sesions, newItem] })
  }


async getSesions() {
      this.setState({sesions:[]});
      await db.collection("users").doc(auth.currentUser?.email).collection('sesions').get()
      .then(res => {
          res.forEach(doc => {

              let newItem = doc.data();
              this.addS(newItem)

          });
      })
      .catch(err => { console.error(err) });
  } 

 

  renderDay(){

    if (this.state.selectedDay !== null) {
       return(<p>Has seleccionado {format(this.state.selectedDay, 'PP')}.</p>);
    } else {
        return( <p>Selecciona día para tu siguiente sesión.</p>)
    }
  }
  renderHours() {
    var data = this.state.hours;
    return data.map(item => 
         (
              <>
              <div className="m-2">
              {item.reserved == false ?
                <Button variant={this.state.selectedHour == item.hour ? "success" : "light"} size="lg"  onClick={() => this.setState({selectedHour:item.hour})}>
                    {item.hour}
                </Button>
              : 
                <Button variant={"outline-danger"}  size="lg" outline="true" onClick={() => this.setState({alertReserved: true})}>
                    {item.hour}
                </Button>
              }
              </div>
              </>
          )
    )
    
  }

    renderModalChange() {
      return(

        <Modal show={this.state.modalChange == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
          <ModalHeader className="modal-header"  >
            <h5 className="modal-title">Razon cambio de terapeuta</h5>
            <button className="btn-close" type="button" onClick={() => this.setState({modalChange:false})} />
         
          </ModalHeader>
          <ModalBody className="">
          

         <div className="btn btn-light m-3   cursor" onClick={() => this.changeT()} >
              <label className=" p-1">No me gusta el terapeuta</label>
          </div>


         <div className="btn btn-light m-3   cursor" onClick={() => this.changeT()} >
              <label className=" p-1">No me siento comodo/a</label>
          </div>

          <div className="btn btn-light m-3  cursor" onClick={() => this.changeT()} >
              <label className=" p-1">Otro</label>
          </div>





          </ModalBody>

          <Modal.Footer>

          <Button variant="secondary" onClick={() => this.setState({modalChange: false,})}>
            Cerrar
          </Button>
        </Modal.Footer>
        </Modal>
        
      )
  }

  renderModalSesion() {
    return(

      <Modal show={this.state.modalSesion == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
          <h5 className="modal-title">Sesion reservada</h5>
          <button className="btn-close" type="button" onClick={() => this.setState({modalSesion:false})} />
        </ModalHeader>
        <ModalBody className="">
        {this.cardTherapistLoad()}
        

       <div className="btn btn-danger m-3   cursor" onClick={() => this.changeT()} >
            <label className=" cursor">Cancelar sesión</label>
        </div>


       <div className="btn btn-light cursor m-3" onClick={() => this.setState({openChat:true})} >
            <label className="cursor"><i className="bi bi-chat fa-comments-dots"></i> Chatear con terapeuta  </label>
        
        </div>
     




        </ModalBody>

        <Modal.Footer>

        <Button variant="secondary" onClick={() => this.setState({modalSesion: false,})}>
          Cerrar
        </Button>
      </Modal.Footer>
      </Modal>
      
    )
}


renderModalTarjeta() {
  return(

    <Modal show={this.state.modalTarjeta == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
      <ModalHeader className="modal-header"  >
        <h5 className="modal-title">Tarjeta regalo</h5>
        <button className="btn-close" type="button" onClick={() => this.setState({modalTarjeta:false})} />
      </ModalHeader>
      <ModalBody className="">

          <div className="row">
            <div className="col-6 bg-transparent ">
                <img src="./assets/images/tarjeta-regalo.png" className="bg-transparent "></img>
            </div>
            <div className="col-6">
              <h4>Canjea tarjeta regalo</h4>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Número en tarjeta" aria-label="Recipient's username" aria-describedby="button-addon2" />

              </div>
              <button className="btn btn-primary" onClick={() => this.redemGift("mYnUTEYgqWVJXOEyrLla")}>
                Canjear tarjeta
              </button>

              <div class="alert alert-light mt-3 shadow" role="alert">
               Al canjear la tarjeta se añadirá a tu cuenta la sesión o sesiones y tu podrás decir cuando tenerla, sin tiempo de caducidad.
              </div>

              <Link to="/precios" className="link cursor">Más información sobre tarjetas regalo</Link>
            </div>
          </div>
      </ModalBody>

      <Modal.Footer>

      <Button variant="secondary" onClick={() => this.setState({modalTarjeta: false,})}>
        Cerrar
      </Button>
    </Modal.Footer>
    </Modal>
    
  )
}

  renderModalSesions() {
      return(

        <Modal show={this.state.modalSesions == true} className="modal center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
          <ModalHeader className="modal-header"  >
            <h5 className="modal-title">Compra más sesiones</h5>
            <button className="btn-close" type="button" onClick={() => this.setState({modalSesions:false})} />
         
          </ModalHeader>
          <ModalBody className="modal-body">
          <div className="container text-center ">
            
          <h4 className="mt-2 mb-4">Suscríbete a Theraphya y empieza ahora mismo un cambio</h4>

        <div className="d-flex align-items-center justify-content-center">

          
         <div className="swiper-slide col-12 col-md-8 row">

                  <div className="card border border-primary p-0">
                    {/* Badge */}
                    {/* <div className="bg-primary text-white rounded position-absolute fw-bold top-0 start-50 translate-middle px-2 py-1 ">Theraphya.com</div> */}

                      {/* Card header */}
                      <div className="card-header bg-light bg-opacity-10 border-bottom border-primary border-opacity-25 p-4">
                        {/* Icon */}
                        <div className="icon-lg bg-primary bg-opacity-1 rounded-circle mb-3">
                            <img src={LogoWhite}  width="40px" />
                        </div>
                        {/* Title and price */}
                        <h6 className="mb-3">Suscripción mensual</h6>
                        <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="$59" data-annual-price="$45">37.9€</span> /sesión</p>
                        <small>Pago mensual de 4 sesiones</small>
                      </div>

                      {/* Card body */}
                      <div className="card-body bg-light bg-opacity-10 p-4">
                        <div className=" text-start">
                          <h6 className="heading-color">Características suscripción</h6>
                          {/* List */}
                          <ul className="list-group list-group-borderless mb-2 mb-sm-4">
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>1x Sesion semanal</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Se te asigna un terapeuta para ti</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Contacto directo con tu terapeuta</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Acceso a todos los terapeutas disponibles</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Una sesión semanal </li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>35€ coste de sesiones extras </li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>24/7 Soporte</li>
                          </ul>
                          {/* Button */}
                          <a href="https://buy.stripe.com/5kA28CctIeeXeOY8wA" className="btn btn-primary mb-0 w-100">Empezar ahora</a>
                        </div>
                      </div>
                    </div>
                </div>

                {/* Item */}
                </div>
            </div>
            
          </ModalBody>

          <Modal.Footer>

          <Button variant="secondary" onClick={() => this.setState({modalSesions: false,})}>
            Cerrar
          </Button>
        </Modal.Footer>
        </Modal>
        
      )
  }
  cerrarBtn() {
    this.setState({modalOpen: false, selectedDay: null, selectedHour: null, tab:1})
    this.resetHours();
  }

  Volver() {
    this.setState({tab:1})
    this.resetHours();

  }


  openChatFun() {
    this.setState({openChat:true, check:true})
    db.collection("users").doc(auth.currentUser?.email).collection("chats").doc(this.state.chatId).set({check:true}, {merge:true})

  }


  cardTherapistLoad() {
    if(this.state.therapistData !== undefined){
    
    return (
      <>
      {cardTherapist(this.state.therapistData,() => this.openChatFun(),this.state.check)}
     
       <div onClick={() => this.setState({modalOpen: false, modalChange: true}) } className="mt-2  link text-danger underline  cursor">
          <a>Cambiar de terapeuta</a>
         </div>
      </>
    )}
     else {
      return (
        <div className="h-200 d-flex align-items-center justify-content-center border-1 text-center">
          <div className="">
          <div class="spinner-grow text-primary " role="status">
              <span class="sr-only">Loading...</span>
          </div>
          <br/>
          <a>Aun no se te ha asignado terapeuta, dentro de poco se te asignara uno, estamos buscando el mejor match para ti</a>
          </div>
      
        </div>
      )
    }

  }
  renderProfile() {
    const moods = ["😊", "😢", "😡", "😴", "😎"];
    return (
      <main>
      <div>
        {/* Page content*/}
      <div className="container   mt-5 ">
      {this.renderModalSesions()}
      {this.renderModalSesion()}
      {this.renderModalChange()}

      {this.renderModalTarjeta()}



      { this.state.openChat ?  
         <Chat 
            show={this.state.openChat} 
            close={() => this.setState({openChat:false})} 
            chatUser={this.state.therapistData} 
            id={this.state.chatId} /> : 
          null}
      <Modal show={this.state.modalOpen} className="modal center"  id="modalCentered"   centered>
          <ModalHeader className="modal-header"  >
            <h5 className="modal-title">Selecciona tu siguiente sesión  </h5>
            
          </ModalHeader>
          <ModalBody className="modal-body">
        
            
           {this.state.sesionsAmmount  <= 0 ? 
           <>
            No te quedan sesiones <br/>
           </>
            :
            <>
               Te quedan <b> {this.state.sesionsAmmount || 0 } </b> sesiones 
            </>
            }
         
            <div className="row">
              <div className=" justify-content-center align-items-center">
                
                { this.state.tab == 1 ? <div>
                  <DayPicker
                    mode="single"
                    dis
                    selected={ this.state.selectedDay}
                    onSelect={(va) => this.setState({selectedDay:va })}
                    footer={this.renderDay()}
                  />
                </div>
                : 
              <div>
              {this.renderDay()}
              Selecciona hora tu sesion
              <div className="form-group">

              <div className="d-flex flex-wrap  justify-content-center">
              {this.renderHours()}

              {this.state.alertReserved ? <label className="text-danger">Hora no disponible. Si quieres otro terapeuta disponible a esa hora pincha <a className="f-bold text-decoration-underline underline text-danger cursor ">aquí</a></label> : null}
              

              </div>

            
              </div>

              </div>
                }

              </div>

         
            


            </div>
          </ModalBody>

          <Modal.Footer>
          

          <Button variant="secondary" onClick={() => this.cerrarBtn()}>
            Cerrar
          </Button>

            { this.state.selectedHour == null ?
            <>
           
             {this.state.tab == 1 && this.state.selectedHour == null ?
               <Button variant="primary" onClick={() => this.selectDayLoad()}>
                  Siguiente
              </Button> 
              :
              <Button variant="primary" onClick={() => this.Volver()}>
                Volver
              </Button> 
              }
            </>
            : 
             <Button variant="primary" onClick={() => this.bookSesion()}>
                Reservar sesión
              </Button> 
            }
            
 

        </Modal.Footer>
      
    </Modal>

          {/* Page content*/}
          <div className="row">
            {/* Sidebar*/}
            <aside className="col-lg-4 col-md-5 pe-xl-4 mb-1">
              {/* Account nav*/}
              <div className="card card-body  shadow   pb-1  mt-3">
                <div className="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4 mt-2"><img className="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg" width={48} />
                  <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-2">
                    <h3 className=" mb-0 ">Tu cuenta</h3>
                    <ul className="list-unstyled fs-sm mt-3 mb-0">
                    <li><a className="nav-link p-0 bold"><i className="fa fa-profile  " />{this.state.name ||auth?.currentUser?.displayName}</a></li>
                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />{auth?.currentUser?.email}</a></li>
                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />Usuario desde {auth?.currentUser?.metadata?.creationTime.slice(0,16)}</a></li>
                    </ul>
                    
                </div>
                 
                </div>

                <a className="btn btn-primary  btn-lg w-100 mb-3 bold text-white"  onClick={() => this.setState({modalOpen: true})}><i className="fi-plus me-2" />Reserva sesión</a>
             {this.state.subscribe !== true ? 
              <a type="button" className="btn btn-outline-secondary" href="https://buy.stripe.com/5kA28CctIeeXeOY8wA">
              ¡Ten tu primera sesion!
            </a>
             :
              <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modalSesions: true})}>
              Sesiones <span className="badge bg-success ms-1">{this.state.sesionsAmmount}</span>
            </button>
            }

            {this.state.loadingContent === false &&  this.state.userData?.answers?.data?.[0]?.data == null? 
               <div to="/preguntas" className="card shadow h mt-2 text-center p-3">Evalua tu estado mental gratuitamente <br></br> <Link className="" to="/preguntas">Test salud mental theraphya</Link>  </div>: null }
            <br/>
              </div>
                {this.state.subscribe !== true ? 
                <a     style={{textDecoration: 'none'}} className="list d-flex justify-content-between align-items-center  shadow  p-2 border-3 rounded mt-3 bg-primary  text-white ">
                 <div className="card-body ">
                      <div className="row mt-3">
                          <div className="col-12">
                              <h4 className="card-text text-white">Plan suscripción</h4>
                              <p>Con el plan PRO de Theraphya accede a 4 sesiones mensuales</p>
                              <a href="https://buy.stripe.com/5kA28CctIeeXeOY8wA" className="btn btn-light">Suscríbete</a>
 
                          </div>
                          

                         
                      </div>
                    

                  </div>  
                  </a>
                     : 
                     <div className="mt-2">
                                 {this.cardTherapistLoad()}
                                 <a target="__blank" href="https://cdn.botpress.cloud/webchat/v2.2/shareable.html?configUrl=https://files.bpcontent.cloud/2024/10/09/14/20241009140807-36FFCE5B.json" className="btn btn-outline-primary  btn-lg w-100 mb-1 bold  mt-3"  ><i class="bi bi-chat-dots"></i> Tu terapeuta virtual</a>
                     </div>
              } 

                  <div className="">
                       <a onClick={() => this.setState({modalTarjeta:true})} className="btn btn-outline-secondary  btn-lg w-100 mb-3 bold  mt-3"  ><i class="bi bi-gift"></i> Tarjeta regalo</a>
                     </div>
            </aside>
            {/* Content*/}
            <div className="col-lg-8 col-md-7 mb-5 mt-2 pt-2">
            { this.state.userData?.answers?.data?.[0]?.data? 
            <section className="pt-lg-0 mb-5">
            <div className=" position-relative">
              {/* SVG decoration START */}
              <figure className="position-absolute top-50 start-50 translate-middle ms-2">
                <svg>
                  <path
                    className="fill-white opacity-4"
                    d="m496 22.999c0 10.493-8.506 18.999-18.999 18.999s-19-8.506-19-18.999 8.507-18.999 19-18.999 18.999 8.506 18.999 18.999z"
                  />
                  <path
                    className="fill-white opacity-4"
                    d="m775 102.5c0 5.799-4.701 10.5-10.5 10.5-5.798 0-10.499-4.701-10.499-10.5 0-5.798 4.701-10.499 10.499-10.499 5.799 0 10.5 4.701 10.5 10.499z"
                  />
                  <path
                    className="fill-white opacity-4"
                    d="m192 102c0 6.626-5.373 11.999-12 11.999s-11.999-5.373-11.999-11.999c0-6.628 5.372-12 11.999-12s12 5.372 12 12z"
                  />
                  <path
                    className="fill-white opacity-4"
                    d="m20.499 10.25c0 5.66-4.589 10.249-10.25 10.249-5.66 0-10.249-4.589-10.249-10.249-0-5.661 4.589-10.25 10.249-10.25 5.661-0 10.25 4.589 10.25 10.25z"
                  />
                </svg>
              </figure>
              {/* SVG decoration END */}
              <div className="row ">
                <div className="col-12">
               
                  <div className="bg-info p-4 p-sm-5 rounded-3">
                    <div className="row position-relative">
                      {/* Svg decoration */}
                      <figure className="fill-white opacity-1 position-absolute top-50 start-0 translate-middle-y">
                        <svg width="141px" height="141px">
                          <path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z" />
                        </svg>
                      </figure>
                      {/* Action box */}
                    
                      <div className="col-11 mx-auto position-relative">
                        <div className="row align-items-center">
                          {/* Title */}
                          <div className="col-lg-7">
						                <div className="icon-lg round bg-opacity-1 rounded-circle mb-3">
                          <img src={LogoWhite} className="rounded-circle rounded"  width="40px" />
                      </div>
                            <h3 className="text-white">Desbloquea tu análisis psicológico</h3>
                           
                          </div>
                          {/* Content and input */}
                          <div className="col-lg-5 text-lg-end">
                          <MindfullnessAI dataAns={this.state.userData?.answers?.data?.[0]?.data}/>
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </section>


          : 
          
         null
          }


            <p className="pt-0 mt-0">Tus siguientes sesiones</p>
            

            {this.renderSesions()}
             { this.state.subscribe === true?  
             <div className="container mt-4">
              <h2 className="mb-4">Tu diario personal</h2>
              
              <label>Como te encuentras hoy</label>

              <div className="mt-2 d-flex justify-content-start mb-3">
                {moods.map((mood) => (
                  <button 
                    key={mood} 
                    className={`btn btn-light mx-2 ${this.state.mood === mood ? "bg-primary " : ""}`}
                    onClick={() => this.handleMoodChange(mood)}
                  >
                    {mood}
                  </button>
                ))}
              </div>
              
              <div className="mb-3">
                <textarea
                  className="form-control"
                  value={this.state.newEntry}
                  onChange={this.handleInputChange}
                  placeholder="Explica porque te sientes así brevemente"
                />
                <button className="btn btn-primary mt-2" onClick={this.addEntry}>
                  Añade al diario
                </button>

                <p className="mt-2">En theraphya puedes mantener un diario personal el cual será complementa privado para ti. <br/>
                Despues de llevar el diario un par de meses podremos identificar contigo y analizar tu progreso basado en el avance.
                </p>
           
              </div>
              <ul className="list-group">
                <h4>Entradas en diario</h4>
                {this.state.entries?.map((entry) => (
                  <li key={entry.id} className="list-group-item">
                    {entry.text} <br />
              <span>{entry.mood}</span> <br />
              <small className="text-primary">{new Date(entry.timestamp.seconds * 1000).toLocaleString()}</small>
            </li>
          ))}
              </ul>
            </div>      : null}

            </div>
            
          </div>
        </div>

      </div>

      </main>
    )
  }

  render() {
    if(this.state.therapistLog !== true) {
      return this.renderProfile()
    } else {
      return <Settings></Settings>
    }
  }

 }


  export default Profile;