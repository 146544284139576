import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { Link, useHistory } from "react-router-dom";

import QuestionsImg from "../assets/questions.png"

import {
  auth,
  registerWithEmailAndPassword,
  registerInWithGoogle,
  db,
} from "../firebase";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom";

function Register() {


  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [step, setStep] = useState(0);


  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  const location = useLocation();

  const [ locationKeys, setLocationKeys ] = useState([])


  const [ answers, setAnsw ] = useState([])


 
  const register = () => {

    registerWithEmailAndPassword(email.toLowerCase(), password,name,answers);
  };  


  const {id} = useParams()

  function LoadUser() {

    if (loading) {
      return;
      
    }
    if (user) { 
      
      history.replace("/cuenta")  
    
    } 
  }

  const getData = async () => {
    if(id) {
      const docSnap = await db.collection("test_data").doc(id).get();
      var d= docSnap.data()
      setAnsw(d)
    }

  }

  useEffect(() => {
    getData()
   
    LoadUser()
    
  
  }, [user, loading]);


  function RegisterForm()    {
      return (
            <div className="RegisterForm slide-in-blurred-left ">
                <div className="container p-2">
                <h2 className="mb-0">Por último crea tu cuenta
                </h2>
                <p className="mb-0">Empieza con tu camino ahora mismo</p>

                {/* Social buttons */}
                          
                {/* Form START */}
                <form>
                  {/* Email */}
                  <div className="input-floating-label form-floating mb-4">
                    <input type="email"
                           className="form-control mt-5"
                           id="floatingInput"
                           placeholder="name@example.com"
                           value={email}
                          onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Dirección email</label>
                  </div>

                  {/* Password */}
                  <div className="input-floating-label form-floating position-relative">
                    <input 
                        className="form-control fakepassword pe-6" id="psw-input"
                        placeholder="Enter your password"
                        value={password}
                         type={
                            showPassword ? "text" : "password"
                         }
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    <label htmlFor="floatingInput">Contraseña</label>
                    <span className="position-absolute top-50 end-0 translate-middle-y p-0 me-2">
                      <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" 
                      onClick={() =>
                          setShowPassword(!showPassword)
                      }
                      ></i>
                    </span>
                  </div>
                  {/* Pswmeter */}

                  <div className="mb-4 mt-3">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="checkbox-1"/>
                      <label className="form-check-label" htmlFor="checkbox-1" ><a> Aceptas los términos de condiciones</a> y <a href="https://theraphya.com/terms" target="__blank">políticas de privacidad </a></label>
                    </div>
                  </div>
                  {/* Button */}
                  <div className="align-items-center mt-0">
                    <div className="d-grid">
                      <button className="btn btn-primary mb-0" type="button" onClick={() => register()}>Crea tu cuenta</button>
                    </div>
                  </div>
                </form>

                  <div id="pswmeter" className="mt-3"></div>
                  
            
                  <div className="position-relative my-5">
                    <hr/>
                    <p className="small position-absolute top-50 start-50 translate-middle bg-body px-4">Or</p>
                  </div>

                      <div className="row mt-2 mb-3">
                  {/* Social btn */}
                  <div className="col-xl-12 d-grid">
                    <a onClick={() => registerInWithGoogle(answers,name)} className="btn border-primary text-primary bg-white mb-2 mb-xxl-0"><i className="fab fa-fw fa-google text-google-icon me-2"></i>Inicia con Google</a>
                  </div>
                  
                  </div>
                  </div>
                </div>
        )
  }




  return(
      <div className="">
      <main>
        <div className="row g-0">
          {/* left */}
          <div className="col-sm-10 col-lg-5 d-flex m-auto vh-100">
            <div className="row w-100 m-auto">
              <div className="col-lg-10 my-2 m-auto">

              <div className="RegisterForm">
               
             
         
              {   RegisterForm()}

                                 
                </div>

      
                {/* Sign IN link */}
                <div className="mt-4 text-center">
                  <span>¿Ya tienes una cuenta? <Link to="/login"> Inicia sesión </Link></span>
                </div>
                
              </div>
            </div>
          </div>

           <div className="col-lg-7 mt-4" >
            {/* Slider START */}
            <div className="vh-100  roundImg "  style={{backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}>
            <div className="d-flex flex-column justify-content-center h-100 container">
                    
              <div className="p-3">
              <h1 className="text-light italic">Si quieres ir rápido ve solo, si quieres llegar lejos ve acompañado.</h1>

              <div className="d-flex justify-content-between mt-5">
                <div>
                  <h5 className="mb-0 fs-2 text-light ">- Proverbio africano</h5>
                </div>

             
              </div>
            </div>
              </div>

            </div>
            </div>
              </div>
    </main>

    </div>

  )
}


export default Register;
