import React from "react";


import Preguntas from "./Preguntas"

import LogoWhite from "../assets/logo-white.png"

class Precios extends React.Component {

  constructor(props) {
    super(props) 
    
    this.state = {
      loading:true,
    }
  } 

  
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const sp = query.get('tab')
    if(sp == 'tarjetas') {
      this.sucessPayment()
    }
  }


  render() {

      return (
        <>
        {/* Header END */}
        {/* **************** MAIN CONTENT START **************** */}
        <main className="mt-5">
     
      
        <section className="pt-0">
          {/* Title and switch */}
          <div className="">


            <div className="inner-container-small text-center  ">
              {/* Title */}
              <h1 className="text-dark  mb-2 ">Empieza tu camino hacia el bienestar</h1>
            <div className="inner-container text-center mb-4 mb-sm-6">
                  <span className="heading-color bg-light small rounded-3 px-3 py-2">💵 Al mejor precio</span>

                </div>
              {/* Switch END */}
            </div>
          </div>  

          {/* Pricing items START */}
          <div className="container mt-n9">
            {/* Slider START */}
            <div className="swiper" >

              <div className="d-flex align-items-center justify-content-center pt-1 row w-100">
              
                <div className="swiper-slide col-12 col-md-6">
                  <div className="card shadow border overflow-hidden p-0">
                    <div className="card-header border-bottom p-4">
                      <div className="icon-lg bg-light rounded-circle mb-3">
                        <i className="bi bi-lightning-charge-fill fa-lg lh-1 heading-color"></i>
                      </div>
                      <h6 className="mb-3">Coste sesión única</h6>
                      <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="46€" data-annual-price="46€">46€</span> /sesión</p>
                      <small>Un solo pago</small>
                    </div>


                    <div className="card-body p-4">
                      <div className=" text-lg-start">

                        <h6>Características</h6>
            
                        <ul className="list-group list-group-borderless mb-2 mb-sm-4">
                          <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg text-primary me-1"></i>Sesión única</li>
                          <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg text-primary me-1"></i>Test de estado mental en Theraphya.com</li>
                          <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg text-primary me-1"></i>Una sesión con Terapeuta acreditado</li>
                          <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg text-primary me-1"></i>Videollamada o llamada con terapeuta</li>

                        </ul>

                        <a href="https://buy.stripe.com/6oEeVoeBQeeX8qA28d" className="btn btn-dark w-100">Comprar sesión </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide col-12 col-md-6">
                  <div className="card border border-primary p-0">
                    {/* Badge */}
                    {/* <div className="bg-primary text-white rounded position-absolute fw-bold top-0 start-50 translate-middle px-2 py-1 ">Theraphya.com</div> */}

                      {/* Card header */}
                      <div className="card-header bg-light bg-opacity-10 border-bottom border-primary border-opacity-25 p-4">
                        {/* Icon */}
                        <div className="icon-lg bg-primary bg-opacity-1 rounded-circle mb-3">
                            <img src={LogoWhite}  width="40px" />
                        </div>
                        {/* Title and price */}
                        <h6 className="mb-3">Suscripción mensual</h6>
                        <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="$59" data-annual-price="$45">37.9€</span> /sesión</p>
                        <small>Pago mensual de 4 sesiones</small>
                      </div>

                      {/* Card body */}
                      <div className="card-body bg-light bg-opacity-10 p-4">
                        <div className=" text-start">
                          <h6 className="heading-color">Características suscripción</h6>
                          {/* List */}
                          <ul className="list-group list-group-borderless mb-2 mb-sm-4">
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>1x Sesion semanal</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Se te asigna un terapeuta para ti</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Contacto directo con tu terapeuta</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Acceso a todos los terapeutas disponibles</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Una sesión semanal </li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>35€ coste de sesiones extras </li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>24/7 Soporte</li>
                          </ul>
                          {/* Button */}
                          <a href="https://buy.stripe.com/5kA28CctIeeXeOY8wA" className="btn btn-primary mb-0 w-100">Empezar ahora</a>
                        </div>
                      </div>
                    </div>
                </div>

                {/* Item */}

                
             
              </div> 

              {/* Slider Pagination */}
              <div className="swiper-pagination swiper-pagination-primary position-relative mt-4"></div>
            </div>
            {/* Slider END */}
          </div>
          {/* Pricing items END */}
        </section>
      


        <div className="container mt-n9">
            {/* Slider START */}
            <h1 className="text-dark  mb-2 ">Tarjetas regalo Theraphya</h1>
            <div className="inner-container  mb-4 mb-sm-6">
                  <span className="heading-color bg-light small rounded-3 px-3 py-2">🎁 Regala bienestar</span>

            </div>
          <div className="shadow  mt-5  mb-3 border-rounded col-12 col-md-12 row p-0 rounded m-3 p-3">
                <div className="col-md-3 col-5 d-flex align-items-center justify-content-center "> 
                  <img src="./assets/images/tarjeta-regalo.png" className="shadow" width={"150px"}></img>
                </div>
                <div class="card-body col-7 col-md-9">
                  <h5 class="card-title">Regala una sesión</h5>
                  <p class="card-text">Regala una sesión de una hora con un psicolo online para alguien a quien quieras ver mejor.</p>
                  <h2>35.9€</h2>
                  <a  target="__blank" href={`https://buy.stripe.com/7sI8x00L0fj1ayI6ov`} class="btn btn-primary">Comprar </a>
                </div>
            </div>

            <div className="shadow  mt-5  mb-3 border-rounded col-12 col-md-12 row p-0 rounded m-3 p-3">
                <div className="col-md-3 col-5 d-flex align-items-center justify-content-center "> 
                  <img src="./assets/images/sesion-pareja.png" width={"150px"}></img>
                </div>
                <div class="card-body col-7 col-md-9">
                  <h5 class="card-title">Regala una sesión de pareja</h5>
                  <p class="card-text">Regala terapia en pareja de 30 minutos.</p>
                  <h2>24.69€</h2>
                  <a target="__blank" href={`https://buy.stripe.com/14k4gK79oeeXeOY5kq`} class="btn btn-primary">Comprar </a>
                </div>
            </div>


          </div>
        <Preguntas />
       </main>
      </>
      
      );

    }
  }


export default Precios;
