
import { db } from "../firebase";




export function lastStatus(lastN) {
    var lastStatusText;
    var diff;
    diff = Date.now().valueOf() - lastN.valueOf();
    lastStatusText = diff/1000/60/60;

    if(lastStatusText < 0.5) {
        lastStatusText = "Activo(a) ahora"
    } else {
        if(lastStatusText > 24) {
        lastStatusText = lastStatusText / 24 
        lastStatusText = `Activo(a) hace ${ lastStatusText.toFixed(0)} días`

        }
        else {
        lastStatusText = `Activo(a) hace ${lastStatusText.toFixed(0)}hrs`
        } 
    }
    return lastStatusText
}


export const  checkChatExists = (user,name,therapist) => {

    const array = [user,therapist]
    array.sort();
    var id = array[0] + array[1];

    var docChat =  db.collection('chats').doc(id).get();
    if(!docChat.exits) {
     
    const chatThera = {
        id: id || "",      
        userRef: db.doc('users/' + user) || "",
        name: name || "",
    }

    if(user !== undefined && therapist !== undefined) {
        db.collection('users').doc(therapist).collection("chats").doc(id).set(chatThera,{merge: true})
    }

  
    }
   
  }
