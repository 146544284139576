
import ReactHtmlParser from 'react-html-parser';
import React, { useState } from "react";

import { Button, Card, Modal, ModalBody, ModalHeader } from "react-bootstrap";

const OPENAI_API_KEY = "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";
 


export default function MindfullnessAI({dataAns}) {

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const promp = "Actúa como un analista psicológico profesional y realiza un análisis detallado del estado mental de una persona en español. Basándote en un array de números del 0 al 5 en el siguiente orden: [Ansiedad, Estrés, Bienestar, Dificultades personales, Depresión], elabora un informe estructurado de la siguiente manera:Formato y estilo:La salida debe ser un texto en formato HTML válido, pero sin incluir etiquetas de código o formato de bloque de código.No incluyas triple comillas invertidas (```) en ningún momento.No precedas la respuesta con html ni ninguna otra etiqueta de formato de código.No uses la palabra código en la respuesta.Estructura:Introducción: Dirígete a la persona con un tono cálido, comprensivo y alentador. Explica brevemente el propósito del análisis.Puntos fuertes: Destaca áreas de resiliencia y bienestar emocional.Puntos débiles: Menciona aspectos que podrían ser un desafío, manteniendo un tono empático.Cómo mejorarlos: Proporciona estrategias y consejos prácticos para mejorar el bienestar.Llamado a la acción: Finaliza con un mensaje de motivación y un botón interactivo con el texto Empieza terapia online que redirija a https://buy.stripe.com/5kA28CctIeeXeOY8wA.Diseño:Usa color de fondo blanco.Resalta puntos clave con el color #61A2F1.Usa una estructura clara y bien formateada con etiquetas HTML, pero evita mencionar que es HTML."
  const old =   "Quiero una analisis del estado mental de una persona como si fueras un analista psicologico, el contenido que recibes es un array de numero de 0-5 con el siguiente orden,[Ansiedad,Estres,Bienestar,Dificultades personales,Depresion ], el resultado tiene que venir en el siguiente formato: introduccion, puntos fuertes de la persona, puntos debiles, como mejorarlos y un boton con el titulo Empieza terapia online  que lleve a https://buy.stripe.com/5kA28CctIeeXeOY8wA, usa html fondo blanco y los highlights con este color #  61A2F1, se hace el analisis hablando a la persona y de forma amistosa, the code don't include html at the begining  "

  const generateResponse = async () => {
    setLoading(true)
    console.log(dataAns.toString())
    if (!prompt) return;

    try {
      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "gpt-4o",
          messages: [{ role: "system", content:  promp},
                     { role: "user", content: dataAns.toString() }],
          max_tokens: 750,
        }),
      });
      
      const data = await res.json();

      console.log(data.choices[0]?.message?.content)
      setResponse(data.choices[0]?.message?.content || "No response generated.");
      setLoading(false)
    } catch (error) {
      console.error("Error fetching AI response:", error);
      setResponse("Error generating response. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col items-center p-6">
    
      <a className="btn btn-light btn-lg d-flex align-items-center justify-content-center text-small text-center" onClick={() => generateResponse()}>
      {loading ?<div class="spinner-border" role="status">
      </div> :  <>Ver tu análisis</> }
      </a>
      {response && (
        <Modal onHide={() => setResponse(null)} show={true} size='xl' className=" mt-4">
          <ModalHeader className="modal-header"  >
            <h5 className="modal-title">Tu análisis</h5>
            <button className="btn-close" type="button" onClick={() => setResponse(null)} />
         
          </ModalHeader>
          <ModalBody>
          {ReactHtmlParser(response)}

          </ModalBody>
        </Modal>
      )}
    </div>
  );
}
