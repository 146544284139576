import React, { useEffect , useState} from "react";


import { useAuthState } from "react-firebase-hooks/auth";


import { logout ,auth} from "../firebase";

import Logo from "../assets/logo.png"
import { Link } from "react-router-dom/cjs/react-router-dom";


function NavbarComponent() {
  const [user, loading] = useAuthState(auth);
  const [LogButton, setLogButton] = useState(false);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (user)
     { setLogButton(false) } else {
       setLogButton(true)
     }
  }, [user, loading]);

    function logoutSesion() {
      logout()
      setLogButton(true)
    }


    return (
          <>
          <header className="navbar-light  ">
          {/* Nav START */}
          <nav className="navbar navbar-expand-xl navbar-light ">
            <div className="container-fluid px-3 px-xl-5 width-100  d-flex">
              {/* Logo START */}
              <Link to="/" className="navbar-brand  " >
                <img src={Logo} width={230} />
              </Link>
              {/* Logo END */}
              {/* Responsive navbar toggler */}
              <button
                className="navbar-toggler ms-auto bg-transparent"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-animation bg-transparent">
                  <span />
                  <span />
                  <span />
                </span>
              </button>
              {/* Main navbar START */}
              <div className="navbar-collapse w-100 collapse justify-content-end align-items-center" id="navbarCollapse">
                {/* Nav category menu START */}
              
                {/* Nav category menu END */}
                {/* Nav Main menu START */}
                <ul className="navbar-nav navbar-nav-scroll text-center  ">
                  {/* Nav item 1 Demos */}
                 
                  {/* Nav item 3 Account */}
                  <li className="nav-item">
                    <a href="https://theraphya.com/" className="nav-link"  
                    >Inicio
                  </a>
                  </li>
                    
                 <li className="nav-item">
                    <a href="https://theraphya.com/nosotros" className="nav-link"  
                    >Nosotros
                  </a>
                  </li>
                
                 <li className="nav-item">
                    <a className="nav-link" href="https://theraphya.com/precios">Suscripciones</a>
                  </li>
                
          
                <li className="nav-item ">
                    <a className="nav-link" href="https://theraphya.com/blog">Blog</a>
                  </li>


                  <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Terapias</a>
                  <ul class="dropdown-menu dropdown-menu">
                    <li>
                      <a href={`https://theraphya.com/terapia/terapia-de-adicciones`} class="dropdown-item">Terapia addicciones</a>
                    </li>
              
                    <li>
                      <a href={`https://theraphya.com/terapia/terapia-de-pareja`} class="dropdown-item">Terapia de pareja</a>
                    </li>


                    <li>
                      <a href={`https://theraphya.com/terapia/terapia-de-niños-adolescentes`} class="dropdown-item">Terapia niños / adolescentes</a>
                    </li>



                    <li>
                      <a href={`https://theraphya.com/terapia/terapia-familiar`} class="dropdown-item">Terapia familiar</a>
                    </li>


                    {/* <li class="dropdown-divider"></li>
                    <li>
                      <a href="#" class="dropdown-item">Terapia España 🇪🇸  </a>
                    </li>

                    <li>
                      <a href="#" class="dropdown-item">Terapia Francia 🇫🇷 </a>
                    </li>

                    <li>
                      <a href="#" class="dropdown-item">Terapia Argentina 🇦🇷 </a>
                    </li>

                    <li>
                      <a href="#" class="dropdown-item">Terapia México 🇲🇽</a>
                    </li>
                  */}

              
                  </ul>
                </li>


                <li className="nav-item d-flex align-items-center justify-content-center">
                    {" "}
                    <a
                      className="btn text-primary   px-2  text-instagram d-flex text-center"
                      href="https://instagram.com/theraphya.app"
                      target="__blank"
                    >
                      <i className="fab fa-fw fa-instagram fs-4" /> <span className="d-lg-none d-md-none d-block">Instagram</span>
                    </a>{" "}
                  </li>

           
                </ul>
                {/* Nav Main menu END */}
                {/* Nav Search START */}
                <div className=" nav-item  ms-3 d-flex justify-content-center">
         
                      <div  className="dropdown nav-link">
                  {LogButton ?
                  <div className="mt-3 mb-3">        
                    <Link to="/register" className="btn btn-primary  ">
                  <span>  <i className="fas fa-user text-white"></i> Crea tu cuenta</span>
                    </Link>
                  </div>
                  :
                  <>        

                  
                    <Link to="/cuenta" className="btn btn-primary m-4 m-md-0">
                      <span >  <i className="fas fa-user text-white"></i> Tu cuenta</span>
                    </Link>
                  </>
     
                    }            
                </div>

                </div>
                    {/* Nav Search END */}
                  </div>
                  {/* Main navbar END */}
                  {/* Profile START */}
            
                  {/* Profile START */}
                </div>
          </nav>
          {/* Nav END */}
        </header>
                {/* */}
             
              
          </>
    
    )
  

}

export default NavbarComponent;
